 @import '~formiojs/dist/formio.full.css';
 .logo {
   height: 1.9em;
}
 .text-blue {
   color: #127abf;
}
 .text-green {
   color: #449414;
}
 