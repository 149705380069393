.box-dash .card .card-body{
  height: 250px;
}

.card-counter{
    box-shadow: 2px 2px 10px #DADADA;
    margin: 5px;
    padding: 20px 10px;
    background-color: #fff;
    height: 100px;
    border-radius: 5px;
    transition: .3s linear all;
  }

  .card-counter:hover{
    box-shadow: 4px 4px 20px #DADADA;
    transition: .3s linear all;
  }

  .card-counter.primary{
    background-color: #007bff;
    color: #FFF;
  }

  .card-counter.danger{
    background-color: #ef5350;
    color: #FFF;
  }  

  .card-counter.success{
    background-color: #66bb6a;
    color: #FFF;
  }  

  .card-counter.info{
    background-color: #26c6da;
    color: #FFF;
  }  

  .card-counter i{
    font-size: 5em;
    opacity: 0.2;
  }

  .card-counter .count-numbers{
    position: absolute;
    top: 20px;
    font-size: 32px;
    display: contents;
  }

  .card-counter .count-name{
    position: absolute;
    top: 65px;
    font-style: italic;
    text-transform: capitalize;
    opacity: 0.5;
    display: contents;
    font-size: 18px;
  }

  table.dataTable td, table.dataTable th{
    vertical-align: middle;
  }

  table.dataTable td, table.dataTable th {
    vertical-align: middle;

  }

  .pos-center {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    height: 50rem;
  }

  .spinner-border {
    animation-duration: 2s;
}


@media print {
 
  .print-btn {
    display: none; 
  }

  /* Ensure the table takes the full width of the printed page */
  #printTable {
    transform: rotate(90deg);
    transform-origin: center center; /* Adjust the origin point as needed */
    width: 100vh; /* Use viewport height as the width */
    position: absolute;
    top: 38%;
    left: 0;
  }
  #printTable .bg-dark.text-white{
    color:#000 !important
  }
}

